import React from "react"
import "./index.scss"


class FoodBlock extends React.Component {
  render() {
    return (
      <section  className="block" id={this.props.headline.toLowerCase()}>
        <section className="block__list">
          <ul>
            <section className="block__image">
              <h3 className="block__headline" id={this.props.headline.toLowerCase()}>{this.props.headline}</h3>
              <img src={this.props.image}></img>
            </section>
          
            {this.props.meals.reverse().map(function(element, index) {
              return (
                <li key={index}>
                  <section className="foodname">
                    <h3>{element.speisename}</h3>
                    <p>{element.allergene}</p>
                  </section>

                  <section className="content">
                    <p className="ingredients">{element.zutaten}</p>
                    <section className="price">
                      <h4>{parseFloat(element.preis).toFixed(2).toString().replace('.', ',')}€</h4>
                    </section>
                  </section>
                </li>
              )
            })}
          </ul>
        </section>
      </section>
    )
  }
}

export default FoodBlock
