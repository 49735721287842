import React from "react"
import Layout from "../components/Layout"
import PageTitleSmall from "../components/PageTitleSmall"
import SEO from "../components/seo"
import img from "../images/img/bg-menu.jpg"
import FoodBlock from "../components/FoodBlock"
import OpeningHoursBlock from "../components/OpeningHoursBlock"
import { graphql } from 'gatsby'
import FoodNav from "../components/FoodNav"
import "./index.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"

const Speisekarte = ({ data }) => {
  const categories = data.allContentfulKategorie.nodes;
  
  return (
    (
      <Layout>
        <SEO title="Speisekarte" />
        <PageTitleSmall title="Speisekarte" img={img} />
        <FoodNav categories={categories}></FoodNav>
        <section data-spy="scroll" data-target="#foodNavbar" data-offset="0">
        {categories.map((category, index) => {
          if(category.speise) return <FoodBlock key={index} image={category.bild.file.url}headline={category.ueberschrift} meals={category.speise} />
          else return null;
        })}
        </section>
        <OpeningHoursBlock />
      </Layout>
    )
  )
}

export const query = graphql`
  {
    allContentfulKategorie(
      sort: {order: ASC, fields: reihenfolge}
      filter: {aktiv: {eq: true}}
    ) {
      nodes {
        ueberschrift
        bild {
          file {
            url
          }
        }
        speise {
          allergene
          preis
          speisename
          zutaten
        }
      }
    }
  }
`;


export default Speisekarte
