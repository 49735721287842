import React from "react"
import "./index.scss"
import VizSensor from "react-visibility-sensor"

class OpeningHoursBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  render() {
    return (
      <VizSensor
        onChange={isVisible => {
          if (isVisible && !this.state.visible) {
            this.setState({
              visible: isVisible,
            })
          }
        }}
      >
        <section className="opening">
          <h3 className={this.state.visible ? "visible" : ""}>
            Unsere Öffnungszeiten
          </h3>
          <ul className={this.state.visible ? "visible" : ""}>
            <li>Montag: 11:00 - 22:00</li>
            <li>Dienstag: 11:00 - 22:00</li>
            <li>Mittwoch: 11:00 - 22:00</li>
            <li>Donnerstag: 11:00 - 22:00</li>
            <li>Freitag: 11:00 - 22:00</li>
            <li>Samstag: 11:00 - 22:00</li>
            <li>Sonntag: 11:00 - 22:00</li>
          </ul>
        </section>
      </VizSensor>
    )
  }
}

export default OpeningHoursBlock
