import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.scss"


class FoodNav extends React.Component {
  render() {
      return(
          <nav id="foodNavbar" className="foodNav ">
              <ul className="navbar-nav">
          {this.props.categories.map((category, index) => {
              return <a className="nav-link foodNav__navItem" href={`#${category.ueberschrift.toLowerCase()}`} key={index}>{category.ueberschrift}</a>
            })}
              </ul>
            </nav>
      )
    }
}

export default FoodNav
