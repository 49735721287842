import React from "react"
import "./index.scss"
import defaultimg from "../../images/img/bg-alpen.jpg"

let titleImg

class PageTitleSmall extends React.Component {
  constructor(props) {
    super(props)

    titleImg = this.props.img ? this.props.img : defaultimg
  }
  render() {
    return (
      <section className="title">
        <img src={titleImg}></img>
        <h1>{this.props.title}</h1>
      </section>
    )
  }
}

export default PageTitleSmall
